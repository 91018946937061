import { inject } from "@angular/core";
import { CanActivateFn, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";

import { UserRole } from "../core/models/Enums/userRole.enum";

export const InternalGuard: CanActivateFn = ():
Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    const router: Router = inject(Router);
    const userDetails = sessionStorage.getItem("userDetails");

    if (userDetails) {
        const user = JSON.parse(userDetails);
        if (user.userRole === UserRole.Internal) {
            return true;
        }
        router.navigate(["/unauthorized"]);
        return false;
    }
    router.navigate(["/"]);
    return false;
};
